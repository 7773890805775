export const portfolioItems = [
  {
    id: 1,
    href: "strong-portfolio-single.html",
    imgSrc: "/assets/images/demo-strong/portfolio/1.jpg",
    imgAlt: "Image Description",
    title: "Rise of Design",
    descr: "Branding, UI/UX Design",
  },
  {
    id: 2,
    href: "strong-portfolio-single.html",
    imgSrc: "/assets/images/demo-strong/portfolio/2.jpg",
    imgAlt: "Image Description",
    title: "Amplitude",
    descr: "UI/UX Design, Development",
  },
  {
    id: 3,
    href: "strong-portfolio-single.html",
    imgSrc: "/assets/images/demo-strong/portfolio/3.jpg",
    imgAlt: "Image Description",
    title: "Medium Scene",
    descr: "Branding, Design",
  },
  {
    id: 4,
    href: "strong-portfolio-single.html",
    imgSrc: "/assets/images/demo-strong/portfolio/4.jpg",
    imgAlt: "Image Description",
    title: "Rise of Design",
    descr: "Branding, UI/UX Design",
  },
  {
    id: 5,
    href: "strong-portfolio-single.html",
    imgSrc: "/assets/images/demo-strong/portfolio/5.jpg",
    imgAlt: "Image Description",
    title: "Amplitude",
    descr: "UI/UX Design, Development",
  },
  {
    id: 6,
    href: "strong-portfolio-single.html",
    imgSrc: "/assets/images/demo-strong/portfolio/6.jpg",
    imgAlt: "Image Description",
    title: "Medium Scene",
    descr: "Branding, Design",
  },
];

export const portfolios1 = [
  {
    id: 7,
    className: "work-item mt-90 mt-md-0 mix development",
    href: "/assets/images/portfolio/masonry/full-project-1.jpg",
    linkClassName: "work-lightbox-link mfp-image",
    imgSrc: "/assets/images/portfolio/masonry/projects-1.jpg",
    imgAlt: "Work Description",
    delay: "1s",
    title: "Medium Scene",
    description: "Lightbox",
  },
  {
    id: 8,
    className: "work-item mix branding design",
    href: "main-portfolio-single-1.html",
    linkClassName: "work-ext-link",
    imgSrc: "/assets/images/portfolio/masonry/projects-2.jpg",
    imgAlt: "Work Description",
    delay: "1s",
    title: "Rise of Design",
    description: "External Page",
  },
  {
    id: 9,
    className: "work-item mt-90 mt-md-0 mix branding",
    href: "main-portfolio-single-1.html",
    linkClassName: "work-ext-link",
    imgSrc: "/assets/images/portfolio/masonry/projects-3.jpg",
    imgAlt: "Work Description",
    delay: "1s",
    title: "Visual Stranger",
    description: "External Page",
  },
  {
    id: 10,
    className: "work-item mix design development",
    href: "main-portfolio-single-1.html",
    linkClassName: "work-ext-link",
    imgSrc: "/assets/images/portfolio/masonry/projects-4.jpg",
    imgAlt: "Work Description",
    delay: "1s",
    title: "Amplitude",
    description: "External Page",
  },
  {
    id: 11,
    className: "work-item mix design",
    href: "main-portfolio-single-1.html",
    linkClassName: "work-ext-link",
    imgSrc: "/assets/images/portfolio/masonry/projects-5.jpg",
    imgAlt: "Work Description",
    delay: "1s",
    title: "Super Awards",
    description: "External Page",
  },
  {
    id: 12,
    className: "work-item mix design branding",
    href: "/assets/images/portfolio/masonry/full-project-6.jpg",
    linkClassName: "work-lightbox-link mfp-image",
    imgSrc: "/assets/images/portfolio/masonry/projects-6.jpg",
    imgAlt: "Work Description",
    delay: "1s",
    title: "Design System",
    description: "Lightbox",
  },
  {
    id: 13,
    className: "work-item mix mix design",
    href: "/assets/images/portfolio/masonry/full-project-7.jpg",
    linkClassName: "work-lightbox-link mfp-image",
    imgSrc: "/assets/images/portfolio/masonry/projects-6.jpg",
    imgAlt: "Work Description",
    delay: "1s",
    title: "Amplitude",
    description: "External Page",
  },
  {
    id: 14,
    className: "work-item mix design development",
    href: "/assets/images/portfolio/masonry/full-project-8.jpg",
    linkClassName: "work-lightbox-link mfp-image",
    imgSrc: "/assets/images/portfolio/masonry/projects-6.jpg",
    imgAlt: "Work Description",
    delay: "1s",
    title: "Super Awards",
    description: "External Page",
  },
];

export const portfolios2 = [
  {
    id: 15,
    imageUrl: "/assets/images/demo-bold/portfolio/1.jpg",
    title: "Medium Scene",
    description:
      "Lorem ipsum dolor siter amet consectetur adipiscing elit sed do eiusmod tempor incididunt labore dolore magna aliqua.",
    link: "bold-portfolio-single.html",
    categories: ["development"],
  },
  {
    id: 16,
    imageUrl: "/assets/images/demo-bold/portfolio/2.jpg",
    title: "Rise of Design",
    description:
      "Proin elementum ipsum vel mauris pellentesque accumsan. Nulla in erat ligula vivamus sed egestas elit, sit amet convallis metus.",
    link: "bold-portfolio-single.html",
    categories: ["branding"],
  },
  {
    id: 17,
    imageUrl: "/assets/images/demo-bold/portfolio/3.jpg",
    title: "Visual Stranger",
    description:
      "Suspendisse scelerisque convallis nibh. Maecenas bibendum porta mattis. Donec quis nibh porta dolor ultrices bibendum vel quis leo.",
    link: "bold-portfolio-single.html",
    categories: ["design", "development"],
  },
  {
    id: 18,
    imageUrl: "/assets/images/demo-bold/portfolio/4.jpg",
    title: "Amplitude",
    description:
      "Aliquam tempus nunc nec rutrum malesuada. Proin pulvinar augue quis pharetra vulputate. Sed lacinia convallis orci vitae condimentum.",
    link: "bold-portfolio-single.html",
    categories: ["branding", "design"],
  },
  {
    id: 19,
    imageUrl: "/assets/images/demo-bold/portfolio/5.jpg",
    title: "Super Awards",
    description:
      "Praesent est lacus, fringilla et justo vel, scelerisque aliquet elit. Mauris malesuada eleifend sapien irere semper a orci ac turpis luctus.",
    link: "bold-portfolio-single.html",
    categories: ["design", "development"],
  },
];

export const portfolios3 = [
  {
    id: 20,
    imgSrc: "/assets/images/demo-brutalist/portfolio/1.jpg",
    imgWidth: 700,
    imgHeight: 848,
    title: "Medium Scene",
    description:
      "Take maximus ligula semper metus pellente mattis. Maecenas volutpat, diam enim. Lorem ipsum dolor sit amet adipiscing elit.",
  },
  {
    id: 21,
    imgSrc: "/assets/images/demo-brutalist/portfolio/2.jpg",
    imgWidth: 848,
    imgHeight: 700,
    title: "Rise of Design",
    description:
      "Maecenas volutpat, diam enim. Lorem ipsum dolor sit amet, cetere adipiscing elit. Maximus ligula semper metus pellentesque mattis.",
  },
  {
    id: 22,
    imgSrc: "/assets/images/demo-brutalist/portfolio/3.jpg",
    imgWidth: 700,
    imgHeight: 848,
    title: "Visual Stranger",
    description:
      "Curabitur iaculis accumsan augue, finibus mauris pretium eu. Duis placerat ex gravida nibh tristique porta nulla facilisi.",
  },
  {
    id: 23,
    imgSrc: "/assets/images/demo-brutalist/portfolio/4.jpg",
    imgWidth: 848,
    imgHeight: 700,
    title: "Rise of Design",
    description:
      "Take maximus ligula semper metus pellente mattis. Maecenas volutpat, diam enim. Lorem ipsum dolor sit amet adipiscing elit.",
  },
  {
    id: 24,
    imgSrc: "/assets/images/demo-brutalist/portfolio/5.jpg",
    imgWidth: 700,
    imgHeight: 848,
    title: "Amplitude",
    description:
      "Posuere felis id arcu blandit sagittis. Eleifeni vestibulum purus, sit amet vulputate risusece fusce aliquet quam eget neque.",
  },
];

export const portfolios4 = [
  {
    id: 25,
    imageSrc: "/assets/images/demo-corporate/portfolio/project-1.jpg",
    title: "How Marketing Wire Support Increased Data Accuracy by 70%",
    number: "70%",
    description: "growth with Resonance",
  },
  {
    id: 26,
    imageSrc: "/assets/images/demo-corporate/portfolio/project-2.jpg",
    title:
      "How Surface Mobility Increased Sales 3X During the Latest Six Months",
    number: "3x",
    description: "sales increased with Resonance",
  },
  {
    id: 27,
    imageSrc: "/assets/images/demo-corporate/portfolio/project-3.jpg",
    title: "How Gen Machine Uses Automations to Grow Their Subscriber Base",
    number: "Zero",
    description: "negative reviews with Resonance",
  },
];

export const portfolios5 = [
  {
    id: 28,
    imageSrc: "/assets/images/demo-elegant/portfolio/1.jpg",
    title: "Medium Scene",
    type: "Lightbox",
    categories: ["development"],
  },
  {
    id: 29,
    imageSrc: "/assets/images/demo-elegant/portfolio/2.jpg",
    title: "Rise of Design",
    type: "External Page",
    categories: ["branding", "design"],
  },
  {
    id: 30,
    imageSrc: "/assets/images/demo-elegant/portfolio/3.jpg",
    title: "Visual Stranger",
    type: "External Page",
    categories: ["branding"],
  },
  {
    id: 31,
    imageSrc: "/assets/images/demo-elegant/portfolio/4.jpg",
    title: "Amplitude",
    type: "External Page",
    categories: ["design", "development"],
  },
  {
    id: 32,
    imageSrc: "/assets/images/demo-elegant/portfolio/5.jpg",
    title: "Super Awards",
    type: "External Page",
    categories: ["design"],
  },
  {
    id: 33,
    imageSrc: "/assets/images/demo-elegant/portfolio/6.jpg",
    title: "Design System",
    type: "Lightbox",
    categories: ["design", "branding"],
  },
  {
    id: 34,
    imageSrc: "/assets/images/demo-elegant/portfolio/7.jpg",
    title: "Rise of Design",
    type: "External Page",
    categories: ["branding", "design"],
  },
  {
    id: 35,
    imageSrc: "/assets/images/demo-elegant/portfolio/8.jpg",
    title: "Medium Scene",
    type: "Lightbox",
    categories: ["development"],
  },
];

export const portfolios6 = [
  {
    id: 36,
    categories: ["development"],
    imgSrc: "/assets/images/demo-fancy/portfolio/project-1.jpg",
    title: "Medium Scene",
    description: "Lightbox",
    lightbox: true,
    lightboxLink: "/assets/images/demo-fancy/portfolio/project-1-large.jpg",
  },
  {
    id: 37,
    categories: ["branding", "design"],
    imgSrc: "/assets/images/demo-fancy/portfolio/project-2.jpg",
    title: "Rise of Design",
    description: "External Page",
    lightbox: false,
    externalLink: "fancy-portfolio-single.html",
  },
  {
    id: 38,
    categories: ["branding"],
    imgSrc: "/assets/images/demo-fancy/portfolio/project-3.jpg",
    title: "Visual Stranger",
    description: "External Page",
    lightbox: false,
    externalLink: "fancy-portfolio-single.html",
  },
  {
    id: 39,
    categories: ["design", "development"],
    imgSrc: "/assets/images/demo-fancy/portfolio/project-4.jpg",
    title: "Amplitude",
    description: "External Page",
    lightbox: false,
    externalLink: "fancy-portfolio-single.html",
  },
  {
    id: 40,
    categories: ["design"],
    imgSrc: "/assets/images/demo-fancy/portfolio/project-5.jpg",
    title: "Super Awards",
    description: "External Page",
    lightbox: false,
    externalLink: "fancy-portfolio-single.html",
  },
  {
    id: 41,
    categories: ["design", "branding"],
    imgSrc: "/assets/images/demo-fancy/portfolio/project-6.jpg",
    title: "Design System",
    description: "Lightbox",
    lightbox: true,
    lightboxLink: "/assets/images/demo-fancy/portfolio/project-6-large.jpg",
  },
];

export const portfolios7 = [
  {
    id: 42,
    categories: ["development"],
    imgSrc: "/assets/images/demo-gradient/portfolio/project-1.jpg",
    title: "Medium Scene",
    description: "Lightbox",
    dataWowDelay: "1s",
  },
  {
    id: 43,
    categories: ["branding", "design"],
    imgSrc: "/assets/images/demo-gradient/portfolio/project-2.jpg",
    title: "Rise of Design",
    description: "External Page",
    dataWowDelay: "1s",
  },
  {
    id: 44,
    categories: ["branding"],
    imgSrc: "/assets/images/demo-gradient/portfolio/project-3.jpg",
    title: "Visual Stranger",
    description: "External Page",
    dataWowDelay: "1s",
  },
  {
    id: 45,
    categories: ["design", "development"],
    imgSrc: "/assets/images/demo-gradient/portfolio/project-4.jpg",
    title: "Amplitude",
    description: "External Page",
    dataWowDelay: "1s",
  },
  {
    id: 46,
    categories: ["design"],
    imgSrc: "/assets/images/demo-gradient/portfolio/project-5.jpg",
    title: "Super Awards",
    description: "External Page",
    dataWowDelay: "1s",
  },
  {
    id: 47,
    categories: ["design", "branding"],
    imgSrc: "/assets/images/demo-gradient/portfolio/project-6.jpg",
    title: "Design System",
    description: "Lightbox",
    dataWowDelay: "1s",
  },
];

export const portfolios8 = [
  {
    id: 48,
    imageSrc: "/assets/images/demo-modern/portfolio/1.jpg",
    title: "Medium Scene",
    categories: "Branding, Design",
    align: "text-center",
  },
  {
    id: 49,
    imageSrc: "/assets/images/demo-modern/portfolio/2.jpg",
    title: "The Rise of Design",
    categories: "Branding, Design",
    align: "text-end",
  },
  {
    id: 50,
    imageSrc: "/assets/images/demo-modern/portfolio/3.jpg",
    title: "Visual Stranger",
    categories: "Branding, Design, Development",
    align: "text-start",
  },
  {
    id: 51,
    imageSrc: "/assets/images/demo-modern/portfolio/4.jpg",
    title: "Amplitude Studios",
    categories: "Branding, Design",
    align: "text-end",
  },
  {
    id: 52,
    imageSrc: "/assets/images/demo-modern/portfolio/5.jpg",
    title: "Super Awards",
    categories: "Design, Development",
    align: "text-center",
  },
];

export const portfolios9 = [
  {
    id: 53,
    className: "work-item",
    categories: ["mix", "development"],
    imgSrc: "/assets/images/demo-slick/portfolio/project-1.jpg",
    imgAlt: "Work Description",
    title: "Medium Scene",
    description: "Lightbox",
    isLightbox: true,
  },
  {
    id: 54,
    className: "work-item",
    categories: ["mt-80", "mt-sm-0", "mix", "branding", "design"],
    imgSrc: "/assets/images/demo-slick/portfolio/project-2.jpg",
    imgAlt: "Work Description",
    title: "Rise of Design",
    description: "External Page",
    isLightbox: false,
  },
  {
    id: 55,
    className: "work-item",
    categories: ["mix", "branding"],
    imgSrc: "/assets/images/demo-slick/portfolio/project-3.jpg",
    imgAlt: "Work Description",
    title: "Visual Stranger",
    description: "External Page",
    isLightbox: false,
  },
  {
    id: 56,
    className: "work-item",
    categories: ["mix", "design", "development"],
    imgSrc: "/assets/images/demo-slick/portfolio/project-4.jpg",
    imgAlt: "Work Description",
    title: "Amplitude",
    description: "External Page",
    isLightbox: false,
  },
  {
    id: 57,
    className: "work-item",
    categories: ["mix", "design"],
    imgSrc: "/assets/images/demo-slick/portfolio/project-5.jpg",
    imgAlt: "Work Description",
    title: "Super Awards",
    description: "External Page",
    isLightbox: false,
  },
  {
    id: 58,
    className: "work-item",
    categories: ["mix", "design", "branding"],
    imgSrc: "/assets/images/demo-slick/portfolio/project-6.jpg",
    imgAlt: "Work Description",
    title: "Design System",
    description: "Lightbox",
    isLightbox: true,
  },
];
export const portfolios10 = [
  {
    id: 59,
    imgSrc: "/assets/images/demo-strong/portfolio/1.jpg",
    imgAlt: "Image Description",
    title: "Rise of Design",
    description: "Branding, UI/UX Design",
  },
  {
    id: 60,
    imgSrc: "/assets/images/demo-strong/portfolio/2.jpg",
    imgAlt: "Image Description",
    title: "Amplitude",
    description: "UI/UX Design, Development",
  },
  {
    id: 61,
    imgSrc: "/assets/images/demo-strong/portfolio/3.jpg",
    imgAlt: "Image Description",
    title: "Medium Scene",
    description: "Branding, Design",
  },
  {
    id: 62,
    imgSrc: "/assets/images/demo-strong/portfolio/4.jpg",
    imgAlt: "Image Description",
    title: "Visual Stranger",
    description: "Branding, UI/UX Design",
  },
  {
    id: 63,
    imgSrc: "/assets/images/demo-strong/portfolio/5.jpg",
    imgAlt: "Image Description",
    title: "Super Awards",
    description: "UI/UX Design, Development",
  },
  {
    id: 64,
    imgSrc: "/assets/images/demo-strong/portfolio/6.jpg",
    imgAlt: "Image Description",
    title: "Design System",
    description: "Branding, Design",
  },
];

export const portfolios11 = [
  {
    id: 65,
    title: "How Marketing Wire Support Increased Data Accuracy by 70%",
    imageUrl: "/assets/images/demo-corporate/portfolio/project-1.jpg",
    number: "70%",
    description: "growth with Resonance",
  },
  {
    id: 66,
    title:
      "How Surface Mobility Increased Sales 3X During the Latest Six Months",
    imageUrl: "/assets/images/demo-corporate/portfolio/project-2.jpg",
    number: "3x",
    description: "sales increased with Resonance",
  },
  {
    id: 67,
    title: "How Gen Machine Uses Automations to Grow Their Subscriber Base",
    imageUrl: "/assets/images/demo-corporate/portfolio/project-3.jpg",
    number: "Zero",
    description: "negative reviews with Resonance",
  },
  {
    id: 68,
    title:
      "How Surface Mobility Increased Sales 3X During the Latest Six Months",
    imageUrl: "/assets/images/demo-corporate/portfolio/project-4.jpg",
    number: "2x",
    description: "sales increased with Resonance",
  },
  {
    id: 69,
    title: "How Gen Machine Uses Automations to Grow Their Subscriber Base",
    imageUrl: "/assets/images/demo-corporate/portfolio/project-5.jpg",
    number: "Zero",
    description: "negative reviews with Resonance",
  },
  {
    id: 70,
    title: "How Marketing Wire Support Increased Data Accuracy by 70%",
    imageUrl: "/assets/images/demo-corporate/portfolio/project-6.jpg",
    number: "80%",
    description: "growth with Resonance",
  },
];

export const portfolios12 = [
  {
    id: 71,
    className: "work-item mix development",
    href: "/assets/images/portfolio/masonry/full-project-1.jpg",
    linkClassName: "work-lightbox-link mfp-image",
    imgSrc: "/assets/images/portfolio/masonry/projects-1.jpg",
    imgAlt: "Work Description",
    delay: "1s",
    title: "Medium Scene",
    description: "Lightbox",
  },
  {
    id: 72,
    className: "work-item mix branding design",
    href: "main-portfolio-single-1.html",
    linkClassName: "work-ext-link",
    imgSrc: "/assets/images/portfolio/masonry/projects-2.jpg",
    imgAlt: "Work Description",
    delay: "1s",
    title: "Rise of Design",
    description: "External Page",
  },
  {
    id: 73,
    className: "work-item  mix branding",
    href: "main-portfolio-single-1.html",
    linkClassName: "work-ext-link",
    imgSrc: "/assets/images/portfolio/masonry/projects-3.jpg",
    imgAlt: "Work Description",
    delay: "1s",
    title: "Visual Stranger",
    description: "External Page",
  },
  {
    id: 74,
    className: "work-item mix design development",
    href: "main-portfolio-single-1.html",
    linkClassName: "work-ext-link",
    imgSrc: "/assets/images/portfolio/masonry/projects-4.jpg",
    imgAlt: "Work Description",
    delay: "1s",
    title: "Amplitude",
    description: "External Page",
  },
  {
    id: 75,
    className: "work-item mix design",
    href: "main-portfolio-single-1.html",
    linkClassName: "work-ext-link",
    imgSrc: "/assets/images/portfolio/masonry/projects-5.jpg",
    imgAlt: "Work Description",
    delay: "1s",
    title: "Super Awards",
    description: "External Page",
  },
  {
    id: 76,
    className: "work-item mix design branding",
    href: "/assets/images/portfolio/masonry/full-project-6.jpg",
    linkClassName: "work-lightbox-link mfp-image",
    imgSrc: "/assets/images/portfolio/masonry/projects-6.jpg",
    imgAlt: "Work Description",
    delay: "1s",
    title: "Design System",
    description: "Lightbox",
  },
  {
    id: 77,
    className: "work-item mix mix design",
    href: "/assets/images/portfolio/masonry/full-project-7.jpg",
    linkClassName: "work-lightbox-link mfp-image",
    imgSrc: "/assets/images/portfolio/masonry/projects-7.jpg",
    imgAlt: "Work Description",
    delay: "1s",
    title: "Amplitude",
    description: "External Page",
  },
  {
    id: 78,
    className: "work-item mix design development",
    href: "/assets/images/portfolio/masonry/full-project-8.jpg",
    linkClassName: "work-lightbox-link mfp-image",
    imgSrc: "/assets/images/portfolio/masonry/projects-8.jpg",
    imgAlt: "Work Description",
    delay: "1s",
    title: "Super Awards",
    description: "External Page",
  },
];

export const portfolios13 = [
  {
    id: 79,
    type: "lightbox",
    mix: "development",
    href: "/assets/images/portfolio/full-project-1.jpg",
    imgSrc: "/assets/images/portfolio/projects-1.jpg",
    imgAlt: "Work Description",
    title: "Green Leaf",
    descr: "Lightbox",
  },
  {
    id: 80,
    type: "external",
    mix: "branding design",
    href: "main-portfolio-single-1.html",
    imgSrc: "/assets/images/portfolio/projects-2.jpg",
    imgAlt: "Work Description",
    title: "Photo Lighting",
    descr: "External Page",
  },
  {
    id: 81,
    type: "external",
    mix: "branding",
    href: "main-portfolio-single-1.html",
    imgSrc: "/assets/images/portfolio/projects-3.jpg",
    imgAlt: "Work Description",
    title: "Green Branch",
    descr: "External Page",
  },
  {
    id: 82,
    type: "external",
    mix: "design development",
    href: "main-portfolio-single-1.html",
    imgSrc: "/assets/images/portfolio/projects-4.jpg",
    imgAlt: "Work Description",
    title: "White Chair",
    descr: "External Page",
  },
  {
    id: 83,
    type: "external",
    mix: "design",
    href: "main-portfolio-single-1.html",
    imgSrc: "/assets/images/portfolio/projects-5.jpg",
    imgAlt: "Work Description",
    title: "White Table",
    descr: "External Page",
  },
  {
    id: 84,
    type: "lightbox",
    mix: "design branding",
    href: "/assets/images/portfolio/full-project-6.jpg",
    imgSrc: "/assets/images/portfolio/projects-6.jpg",
    imgAlt: "Work Description",
    title: "The Book",
    descr: "Lightbox",
  },
  {
    id: 85,
    type: "external",
    mix: "branding",
    href: "main-portfolio-single-1.html",
    imgSrc: "/assets/images/portfolio/projects-7.jpg",
    imgAlt: "Work Description",
    title: "Green Branch",
    descr: "External Page",
  },
  {
    id: 86,
    type: "external",
    mix: "design development",
    href: "main-portfolio-single-1.html",
    imgSrc: "/assets/images/portfolio/projects-8.jpg",
    imgAlt: "Work Description",
    title: "White Chair",
    descr: "External Page",
  },
];

export const allPortfolios = [
  ...portfolioItems,
  ...portfolios1,
  ...portfolios2,
  ...portfolios3,
  ...portfolios4,
  ...portfolios5,
  ...portfolios6,
  ...portfolios7,
  ...portfolios8,
  ...portfolios9,
  ...portfolios10,
  ...portfolios11,
  ...portfolios12,
  ...portfolios13,
];
